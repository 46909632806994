import { LitElement, css } from "lit-element";

export type Platforms = string[];
export interface Inspector {}
export class PGDElement extends LitElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
        }
        :host[disabled] {
          pointer-events: none;
        }
        :host[readonly] {
          pointer-events: none;
          user-select: none;
        }
      `
    ];
  }
  platform() {
    return ["web", "mobile"];
  }
  isPlatform(platform: "web" | "mobile") {
    return this.platform().indexOf(platform) > -1;
  }
}
