import { Options } from "selenium-webdriver";

export function chartDataConvertor(data: any) {
  let result = {
    category_: data.category_
  };
  Object.keys(data).forEach((key, idx) => {
    if (key !== "category_") {
      var value = data[key];
      var label = key;
      if (value instanceof Array && key !== "options_") {
        result[label] = value;
      }else if(value instanceof Array && key === "options_"){
        value.forEach(val => {
          if(val["data"]){
            if(val["label"]){
              result[val["label"]] = val["data"];
            }
          }
        });
      }else {
        if (value.data) {
          if (value.label) {
            result[value.label] = value.data;
          } else {
            result[label] = value.data;
          }
        } else {
          if (value.label) {
            result[value.label] = value;
          } else {
            result[label] = value;
          }
        }
      }
    }
  });
  return result;
}

export function ChartDataOptionsConverter(data : any){
  let options = [];
  Object.keys(data).forEach((key, idx) => {
    var value = data[key];
    if (key !== "category_") {
      if(value instanceof Array && key === "options_"){
        value.forEach(val => {
          if(val["type"]){
            if(val["col"]){
              options.push({type : val.type,col : val.col})
            }
          }
        })
      }
     if (value instanceof Object) {
        if(value.type){
          if(value.col){
            options.push({type : value.type,col : value.col})
          }
        }
      }
    }
  });
  return options;
}
