export const ChartCtx: chartAPI.IChartCtx = {
  providers: {},
  define: function(name: string, provider: chartAPI.ChartComponentAPI) {
    console.debug("[[define]]:", name, provider);
    Object.defineProperty(this.providers, name, {
      get: () => provider
    });
    return true;
  },
  get: function(name) {
    return this.providers[name];
  },
  size: function() {
    return Object.getOwnPropertyNames(this.providers).length;
  }
};
(function(ctx) {
  Object.defineProperty(ctx, "ChartCtx", {
    get: function() {
      return ChartCtx;
    }
  });
})(window);
